import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const API = apiPath.psm;

/**
 * 分页查询计划(中煤)
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPagePlansZm(params) {
  return axios.get(API + "/page/plans-zm", { params: { ...params } });
}

/**
 * 获取计划详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoPlansZmById(id) {
  return axios.get(API + `/detail/plans-zm/${id}`);
}
/**
 * 是否有执行权限
 * @param planId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function authExecuteForPlanZm(planId) {
  return axios.get(API + `/check/plans/privilege-zm/${planId}`);
}
/**
 * 分页查询排查记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageInspectRecordsZm(params) {
  return axios.get(API + "/page/check/records-zm", { params: { ...params } });
}
/**
 * 新增排查任务记录,排查项可不带
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createInspectRecordsZm(data) {
  return axios.post(API + "/add/check/records-zm", data);
}
/**
 * 更新排查任务记录
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateInspectRecordsZmById(id, data) {
  return axios.post(API + "/update/check/records-zm/" + id, data);
}
/**
 * 查询排查任务记录详细信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoInspectRecordZmById(id) {
  return axios.get(API + "/content/check/records-zm/" + id);
}
