import { deepFreeze } from "@/utils";

export const fieldsConf = deepFreeze([
  {
    prop: "name",
    label: "计划名称"
  },
  {
    prop: "orgLevelName",
    label: "排查级别"
  },
  {
    prop: "checkTypeName",
    label: "排查类型",
    type: "textarea"
  },
  {
    prop: "startDate",
    label: "计划开始时间"
  },
  {
    prop: "endDate",
    label: "计划结束时间"
  },
  {
    prop: "departmentName",
    label: "排查部门",
    type: "textarea"
  }
]);

export const checkTypes = deepFreeze([
  {
    label: "日常排查",
    value: "1"
  },
  {
    label: "综合性排查",
    value: "2"
  },
  {
    label: "专业性排查",
    value: "3"
  },
  {
    label: "季节性排查",
    value: "4"
  },
  {
    label: "重点时段及节假日排查",
    value: "5"
  },
  {
    label: "事故类比排查",
    value: "6"
  },
  {
    label: "复产复工前排查",
    value: "7"
  },
  {
    label: "外聘专家排查",
    value: "8"
  },
  {
    label: "执法检查",
    value: "9"
  }
]);

export const planStatusTypes = deepFreeze([
  { label: "已终止", value: "-1" },
  { label: "执行中", value: "0" },
  { label: "已完成", value: "1" },
  { label: "待执行", value: "2" }
]);

export const ICON_STATUS = {
  "-1": {
    iconName: "yizhongzhi",
    iconColor: "#C0C4CC"
  },
  "0": {
    iconName: "zhihangzhong",
    iconColor: "#F3961C"
  },
  "1": {
    iconName: "yiwancheng",
    iconColor: "#8EB433"
  },
  "2": {
    iconName: "daizhihang",
    iconColor: "#1676FF"
  }
};
