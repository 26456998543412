import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const API = apiPath.psm;

/**
 * 分页查询计划
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPagePlans(params) {
  return axios.get(API + "/page/plans", { params: { ...params } });
}

/**
 * 获取计划详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoPlansById(id) {
  return axios.get(API + `/detail/plans/${id}`);
}

/**
 * 分页查询排查记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageInspectRecords(params) {
  return axios.get(API + "/page/check/records", { params: { ...params } });
}

/**
 * 新增排查任务记录,排查项可不带
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createInspectRecords(data) {
  return axios.post(API + "/add/check/records", data);
}

/**
 * 更新排查任务记录
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateInspectRecordsById(id, data) {
  return axios.post(API + "/update/check/records/" + id, data);
}

/**
 * 查询排查任务记录详细信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoInspectRecordById(id) {
  return axios.get(API + "/content/check/records/" + id);
}

/**
 * 查询未关联的隐患
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageNotRelateTrouble(params) {
  return axios.get(API + "/not/relate/troubles", { params: { ...params } });
}

/**
 * 批量更新隐患
 * @param planId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function batchUpdateTroubleByPlanId(planId, data = []) {
  return axios.post(API + `/batch/modify/planId/${planId}`, data);
}

/**
 * 关联排查任务的隐患列表
 * @param {*} params
 */
export const getRelatedTroubleList = params =>
  axios.get(API + "/page/record/rel/troubles", {
    params: { ...params, related: true }
  });

/**
 * 关联联排查任务的隐患列表
 * @param {*} params
 */
export const getUnRelatedTroubleList = params =>
  axios.get(API + "/page/record/rel/troubles", {
    params: { ...params, related: false }
  });

/**
 * 是否有执行权限
 * @param planId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function authExecuteForPlan(planId) {
  return axios.get(API + `/check/plans/privilege/${planId}`);
}

/**
 * 排查计划 - 排查周期下拉
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectCycleTypes() {
  return axios.get(API + "/cycles");
}

export const postListForRiskItem = data => {
  return axios.post(API + `/risk-measures`, data);
};
